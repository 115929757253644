import React, { useEffect } from "react";
import { FaFacebookF, FaPinterestP } from "react-icons/fa";
import {
  AiFillInstagram,
  AiOutlineTwitter,
  AiFillYoutube,
} from "react-icons/ai";
import { Alert } from "reactstrap";

import "./ThankYouPage.css";

function ThankYouPage({ invoiceDetails, customerDetails, language }) {
  useEffect(() => {
    if (
      invoiceDetails?.invoice_number &&
      invoiceDetails?.payment_code &&
      customerDetails?.firstName
    ) {
    } else {
      console.log(invoiceDetails, customerDetails);
      //window.location.href = "/";
    }
  }, [invoiceDetails, customerDetails]);

  return (
    <div>
      {language === "es" && (
        <>
          <div className="container mt-5">
            <div className="row d-flex justify-content-center ">
              <div className="col-sm-8 mt-1">
                <p className="header-sub-text-style-thankyou font-size-set-42">
                  <b>Gracias {customerDetails?.firstName}!</b>
                </p>
              </div>
            </div>
            <div className="row d-flex justify-content-center ">
              <div className="col-sm-8 mt-1">
                <p className="steps-p-sub font-size-set-14 text-align-set">
                  Gracias por la reserva de sus vacaciones. Uno de nuestros
                  agentes se comunicará con usted en breve. Hemos enviado una
                  factura pagada a su bandeja de entrada de correo electrónico.
                  A continuación se muestran algunos de los detalles de pago. Si
                  tiene alguna inquietud, comuníquese con nuestro agente con los
                  detalles de la factura.
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row d-flex justify-content-center mt-3">
              <div className="col-sm-4 col-md-5 col-lg-4 col-xl-4 mb-5">
                <Alert color="info">
                  <h4 className="alert-heading">Detalles del Cliente</h4>
                  <hr />
                  <p className="mb-0">
                    <b>Primer Nombre:</b> {customerDetails?.firstName}
                  </p>
                  <p className="mb-0">
                    <b>Apellido:</b> {customerDetails?.lastName}
                  </p>
                  <p className="mb-0">
                    <b>Email:</b> {customerDetails?.email}
                  </p>
                  <p className="mb-0">
                    <b>Número de Teléfono:</b> {customerDetails?.phoneNumber}
                  </p>
                </Alert>
              </div>
              <div className="col-sm-4 col-md-5 col-lg-4 col-xl-4 mb-5">
                <Alert color="success">
                  <h4 className="alert-heading">Detalles del Pago</h4>
                  <hr />
                  <p className="mb-0">
                    <b>Número de Factura:</b> {invoiceDetails?.invoice_number}
                  </p>
                  <p className="mb-0">
                    <b>Código de Pago:</b> {invoiceDetails?.payment_code}
                  </p>
                  <p className="mb-0">
                    <b>Tipo de Pago:</b> {invoiceDetails?.payment_type_code}
                  </p>
                  <p className="mb-0">
                    <b>Monto del Pago:</b> ${customerDetails?.packagePrice}
                  </p>
                  <p className="mb-0">
                    <b>Nombre del Paquete:</b> {customerDetails?.packageName}
                  </p>
                </Alert>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-sm-4 col-md-5 col-lg-4 col-xl-4 mb-5">
                <div className="step-card">
                  <center>
                    <p className="steps-p-head font-size-set-16">
                      Mantente informado con las últimas noticias
                    </p>
                    <ul className="social-network social-circle">
                      <li>
                        <a
                          href="https://www.facebook.com/vacationcoupon"
                          target="_blank"
                          className=""
                          rel="noopener noreferrer"
                          title="Facebook"
                        >
                          <FaFacebookF />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/vacationcoupon"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="icoInstagram"
                          title="Instagram"
                        >
                          <AiFillInstagram style={{ fontSize: "20px" }} />
                        </a>
                      </li>
                      {/* <li>
                    <a
                      href="https://twitter.com/Buenocom1"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icoInstagram"
                      title="Twitter"
                    >
                      <AiOutlineTwitter style={{ fontSize: "20px" }} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCrNPFN903j5DUivqMYV1pRA"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icoInstagram"
                      title="Youtube"
                    >
                      <AiFillYoutube style={{ fontSize: "20px" }} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.pinterest.es/buenotravel"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icoInstagram"
                      title="Pinterest"
                    >
                      <FaPinterestP style={{ fontSize: "20px" }} />
                    </a>
                  </li> */}
                    </ul>
                    <p className="steps-p-sub-thankx font-size-set-14">
                      o visita nuestro sitio web aqui.
                    </p>
                    <a href="https://vacationcoupon.com/">
                      www.vacationcoupon.com
                    </a>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {language === "en" && (
        <>
          <div className="container mt-5">
            <div className="row d-flex justify-content-center ">
              <div className="col-sm-8 mt-1">
                <p className="header-sub-text-style-thankyou font-size-set-42">
                  <b>Thank You {customerDetails?.firstName}!</b>
                </p>
              </div>
            </div>
            <div className="row d-flex justify-content-center ">
              <div className="col-sm-8 mt-1">
                <p className="steps-p-sub font-size-set-14 text-align-set">
                  Thank you for booking your vacation. One of our agents will
                  contact you shortly. We have sent a paid invoice to your email
                  inbox. Below are some of the payment details. If you have any
                  concerns, please contact our agent with the invoice details.
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row d-flex justify-content-center mt-3">
              <div className="col-sm-4 col-md-5 col-lg-4 col-xl-4 mb-5">
                <Alert color="info">
                  <h4 className="alert-heading">Customer Details</h4>
                  <hr />
                  <p className="mb-0">
                    <b>First Name:</b> {customerDetails?.firstName}
                  </p>
                  <p className="mb-0">
                    <b>Last Name:</b> {customerDetails?.lastName}
                  </p>
                  <p className="mb-0">
                    <b>Email:</b> {customerDetails?.email}
                  </p>
                  <p className="mb-0">
                    <b>Phone Number:</b> {customerDetails?.phoneNumber}
                  </p>
                </Alert>
              </div>
              <div className="col-sm-4 col-md-5 col-lg-4 col-xl-4 mb-5">
                <Alert color="success">
                  <h4 className="alert-heading">Payment Details</h4>
                  <hr />
                  <p className="mb-0">
                    <b>Invoice Number:</b> {invoiceDetails?.invoice_number}
                  </p>
                  <p className="mb-0">
                    <b>Payment Code:</b> {invoiceDetails?.payment_code}
                  </p>
                  <p className="mb-0">
                    <b>Payment Type:</b> {invoiceDetails?.payment_type_code}
                  </p>
                  <p className="mb-0">
                    <b>Total Price:</b> ${customerDetails?.packagePrice}
                  </p>
                  <p className="mb-0">
                    <b>Package Name:</b> {customerDetails?.packageName}
                  </p>
                </Alert>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-sm-4 col-md-5 col-lg-4 col-xl-4 mb-5">
                <div className="step-card">
                  <center>
                    <p className="steps-p-head font-size-set-16">
                      Stay informed with the latest news
                    </p>
                    <ul className="social-network social-circle">
                      <li>
                        <a
                          href="https://www.facebook.com/vacationcoupon"
                          target="_blank"
                          className=""
                          rel="noopener noreferrer"
                          title="Facebook"
                        >
                          <FaFacebookF />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/vacationcoupon"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="icoInstagram"
                          title="Instagram"
                        >
                          <AiFillInstagram style={{ fontSize: "20px" }} />
                        </a>
                      </li>
                      {/* <li>
                    <a
                      href="https://twitter.com/Buenocom1"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icoInstagram"
                      title="Twitter"
                    >
                      <AiOutlineTwitter style={{ fontSize: "20px" }} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCrNPFN903j5DUivqMYV1pRA"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icoInstagram"
                      title="Youtube"
                    >
                      <AiFillYoutube style={{ fontSize: "20px" }} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.pinterest.es/buenotravel"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icoInstagram"
                      title="Pinterest"
                    >
                      <FaPinterestP style={{ fontSize: "20px" }} />
                    </a>
                  </li> */}
                    </ul>
                    <p className="steps-p-sub-thankx font-size-set-14">
                      or visit our website here
                    </p>
                    <a href="https://vacationcoupon.com/">
                      www.vacationcoupon.com
                    </a>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ThankYouPage;
