import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";

import { editUserEnd } from "../../../api/authAPI";

import "./EditUser.css";

const EditUser = () => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isUserCreateSuccess, setIsUserCreateSuccess] = useState(false);

  function successSubmission(data) {
    localStorage.setItem("vc-auth-firstName", data.firstName);
    localStorage.setItem("vc-auth-lastName", data.lastName);
    localStorage.setItem("vc-auth-email", data.email);
    localStorage.setItem("vc-auth-token", data.token);
    localStorage.setItem("vc-auth-role", data.role);
    localStorage.setItem("vc-is-valid-auth", true);

    setIsUserCreateSuccess(true);
    window.location.href = "/";
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          <div className="edit-form-div">
            <Formik
              initialValues={{
                firstName: localStorage.getItem("vc-auth-firstName"),
                lastName: localStorage.getItem("vc-auth-lastName"),
                email: localStorage.getItem("vc-auth-email"),
                password: "",
                newPassword: "",
                confirmNewPassword: "",
              }}
              validationSchema={Yup.object({
                firstName: Yup.string()
                  .min(2, "FirstName cannot smaller than 2 characters")
                  .max(50, "FirstName cannot exceed 50 characters")
                  .required("Required"),
                lastName: Yup.string()
                  .min(2, "LastName cannot smaller than 2 characters")
                  .max(50, "LastName cannot exceed 50 characters")
                  .required("Required"),
                email: Yup.string()
                  .max(100, "Email cannot exceed 100 characters")
                  .email("Invalid Email")
                  .required("Required"),
                password: Yup.string()
                  .min(2, "Password cannot smaller than 2 characters")
                  .max(60, "Password cannot exceed 60 characters"),
                newPassword: Yup.string()
                  .min(2, "Password cannot smaller than 2 characters")
                  .max(60, "Password cannot exceed 60 characters"),
                confirmNewPassword: Yup.string().when("newPassword", {
                  is: (val) => (val && val.length > 0 ? true : false),
                  then: Yup.string().oneOf(
                    [Yup.ref("newPassword")],
                    "Both password need to be the same"
                  ),
                }),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);

                editUserEnd({
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  password: values.password,
                  newPassword: values.newPassword,
                  confirmNewPassword: values.confirmNewPassword,
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        successSubmission(data["data"]);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("¡Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } else {
                      failSubmission("Error!", "Please try again later!");
                    }
                  } catch (error) {
                    if (data) {
                      if (data["result"]) {
                        if (data["result"] === "FAILED") {
                          //console.log(data["data"]);
                          failSubmission(
                            "¡Error!",
                            data["data"]["errorMessage"]
                          );
                        } else {
                          failSubmission("Error!", "Please try again later!");
                        }
                      } else {
                        failSubmission("Error!", "Please try again later!");
                      }
                    } else {
                      failSubmission("Error!", "Please try again later!");
                    }
                  }
                });
              }}
            >
              <Form>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-12 mb-4">
                    <div className="row">
                      <div className="col-sm-12 mb-4 mt-5">
                        <h5 className="head-text-invoice">Edit User Details</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="firstName"
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="firstName">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="lastName"
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="lastName">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="email"
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            disabled={true}
                          />
                          <ErrorMessage name="email">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col text-center">
                        {localStorage.getItem("vc-auth-role") === "admin" ? (
                          <h3>
                            <span className="badge rounded-pill bg-info lg">
                              Admin
                            </span>
                          </h3>
                        ) : (
                          <h3>
                            <span className="badge rounded-pill bg-success">
                              Agent
                            </span>
                          </h3>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-4 mt-5">
                        <h5 className="head-text-invoice">Change Password</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="password"
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="password">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="newPassword"
                            type="password"
                            className="form-control"
                            placeholder="New Password"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="newPassword">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="confirmNewPassword"
                            type="password"
                            className="form-control"
                            placeholder="Confirm New Password"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="confirmNewPassword">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    {isUserCreateSuccess && (
                      <div className="row">
                        <div className="col text-center">
                          <>
                            <Alert color="success">
                              <p>Successfully User Updated</p>
                              {/* <hr />
                              <p className="mb-0">
                                New Your Successfully Created
                              </p> */}
                            </Alert>
                          </>
                        </div>
                      </div>
                    )}
                    {isFailSubmission && (
                      <div className="container">
                        <div className="row">
                          <div className="col text-center">
                            <>
                              <Alert color="danger">
                                {/* <p>{errorMsg.head}</p>
                                <hr /> */}
                                <p className="mb-0">{errorMsg.sub}</p>
                              </Alert>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <button
                            type="submit"
                            className="button-submit-style"
                            disabled={isLoading}
                          >
                            Confirm Details
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
