import { useMemo } from "react";
import { useEffect, useState } from "react";

import DataTable from "react-data-table-component";
import { readAllUsers } from "../../../api/authAPI";

import "./AllUsers.css";

const AllUsers = () => {
  const [userItems, setUserItems] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    readAllUsers().then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setUserItems(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        if (data) {
          if (data["result"]) {
            if (data["result"] === "FAILED") {
              setPending(true);
            } else {
              setPending(true);
            }
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      }
    });
  }, [userItems.length]);

  const columns = useMemo(
    () => [
      {
        name: "First Name",
        selector: (row) => row.firstName,
        sortable: true,
        grow: 2,
      },
      {
        name: "Last Name",
        selector: (row) => row.lastName,
        sortable: true,
        grow: 2,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
        grow: 3,
      },
      {
        name: "Responsible",
        selector: (row) => row.responsible,
        sortable: true,
        grow: 2,
      },
      {
        name: "Role",
        selector: (row) =>
          row.role === "admin" ? (
            <h3>
              <span className="badge rounded-pill bg-info lg">Admin</span>
            </h3>
          ) : (
            <h3>
              <span className="badge rounded-pill bg-success">Agent</span>
            </h3>
          ),
      },
      {
        name: "Modified",
        selector: (row) => row.modified,
        sortable: true,
        grow: 2,
      },
      {
        name: "Created",
        selector: (row) => row.created,
        sortable: true,
        grow: 2,
      },
    ],
    []
  );

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        fontSize: "16px",
      },
    },
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <DataTable
            title="Users"
            data={userItems}
            columns={columns}
            fixedHeader
            fixedHeaderScrollHeight="100%"
            pagination
            progressPending={pending}
            customStyles={customStyles}
          />
        </div>
      </div>
    </div>
  );
};

export default AllUsers;
