import React, { Suspense, useEffect, useState } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";

import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import HomePage from "./RootPage/HomePage/HomePage";
import InvoiceForm from "./RootPage/InvoiceGenerator/InvoiceForm/InvoiceForm";
import ReadInvoice from "./RootPage/InvoiceGenerator/ReadInvoice/ReadInvoice";
import InvoiceGenerator from "./RootPage/InvoiceGenerator/InvoiceGenerator";
import Page404 from "./RootPage/page404/Page404";
import Payment from "./RootPage/PaymentGenerator/Payment/Payment";
import PaymentGenerator from "./RootPage/PaymentGenerator/PaymentGenerator";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Login from "./RootPage/auth/login/Login";
import Signin from "./RootPage/auth/signIn/SignIn";
import AllUsers from "./RootPage/auth/allUsers/AllUsers";
import AllPaymentRequest from "./RootPage/PaymentGenerator/AllPaymentRequest/AllPaymentRequest";
import AllPayments from "./RootPage/payment/AllPayments/AllPayments";

function App() {
  const { pathname } = useLocation();

  return (
    <ScrollToTop>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
          <ProtectedRoute path="/" exact component={HomePage} />
          <ProtectedRoute
            path="/invoice-generator"
            exact
            component={InvoiceForm}
          />
          <ProtectedRoute path="/all-invoices" exact component={ReadInvoice} />
          <ProtectedRoute
            path="/invoice-generator/:languageId/:invoiceId"
            exact
            component={InvoiceGenerator}
          />
          <ProtectedRoute path="/payment-generator" exact component={Payment} />
          <ProtectedRoute
            path="/all-payment-request"
            exact
            component={AllPaymentRequest}
          />
          <Route
            path="/p/:languageId/:paymentToken"
            exact
            render={(props) => <PaymentGenerator />}
          />
          <ProtectedRoute path="/all-checkouts" exact component={AllPayments} />
          <ProtectedRoute path="/all-users" exact component={AllUsers} />
          <Route path="/login" exact render={(props) => <Login />} />
          <Route path="/sign-in" exact render={(props) => <Signin />} />
          <Route path="*" exact={true} component={Page404} />
        </Switch>
      </Suspense>
    </ScrollToTop>
  );
}

export default App;
