import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, FormGroup, Label } from "reactstrap";
import { paymentRequestCreate } from "../../../api/PaymentRequestAPI";

import "./PaymentInvoiceNoHaveForm.css";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const PaymentInvoiceNoHaveForm = ({ backToPayment, paymentDetails }) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSentSuccess, setIsEmailSentSuccess] = useState(false);
  const [invoiceSentData, setInvoiceSentData] = useState({
    invoiceNumber: "",
    email: "",
  });

  function successSubmission(data) {
    console.log(data);
    setIsEmailSentSuccess(true);
    setInvoiceSentData({ ...data });
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  useEffect(() => {}, [paymentDetails]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="invoice-form-div">
            <Formik
              enableReinitialize
              initialValues={{
                firstName: paymentDetails.firstName,
                lastName: paymentDetails.lastName,
                email: paymentDetails.email,
                phone: paymentDetails.phoneNumber,
                packageName: paymentDetails.packageName,
                packagePrice: paymentDetails.price,
                invoiceNumber: paymentDetails.invoiceNumber,
                language: paymentDetails.language,
              }}
              validationSchema={Yup.object({
                firstName: Yup.string()
                  .min(4, "Debe tener 4 caracteres o más")
                  .max(50, "Debe tener 50 caracteres o menos")
                  .required("Requerido"),
                lastName: Yup.string()
                  .min(4, "Debe tener 4 caracteres o más")
                  .max(50, "Debe tener 50 caracteres o menos")
                  .required("Requerido"),
                email: Yup.string()
                  .max(100, "Debe tener 100 caracteres o menos")
                  .email("Dirección de correo electrónico inválida")
                  .required("Requerido"),
                phone: Yup.string()
                  .matches(phoneRegExp, "El número de teléfono no es válido")
                  .required("Requerido"),
                packageName: Yup.string()
                  .min(4, "Debe tener 4 caracteres o más")
                  .max(100, "Debe tener 100 caracteres o menos")
                  .required("Requerido"),
                packagePrice: Yup.string()
                  .min(4, "Debe tener 1 caracteres o más")
                  .max(20, "Debe tener 20 caracteres o menos")
                  .required("Requerido"),
                language: Yup.string().required("Requerido"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);

                paymentRequestCreate({
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  phoneNumber: values.phone,
                  packageName: values.packageName,
                  packagePrice: values.packagePrice,
                  invoiceNumber: values.invoiceNumber,
                  language: values.language,
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        successSubmission(data["data"]);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("¡Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission(
                          "¡Error!",
                          "¡Por favor, inténtelo de nuevo más tarde!"
                        );
                      }
                    } else {
                      failSubmission(
                        "¡Error!",
                        "¡Por favor, inténtelo de nuevo más tarde!"
                      );
                    }
                  } catch (error) {
                    if (data) {
                      if (data["result"]) {
                        if (data["result"] === "FAILED") {
                          //console.log(data["data"]);
                          failSubmission(
                            "¡Error!",
                            data["data"]["errorMessage"]
                          );
                        } else {
                          failSubmission(
                            "¡Error!",
                            "¡Por favor, inténtelo de nuevo más tarde!"
                          );
                        }
                      } else {
                        failSubmission(
                          "¡Error!",
                          "¡Por favor, inténtelo de nuevo más tarde!"
                        );
                      }
                    } else {
                      failSubmission(
                        "¡Error!",
                        "¡Por favor, inténtelo de nuevo más tarde!"
                      );
                    }
                  }
                });
              }}
            >
              <Form>
                <div className="row">
                  <div className="col-sm-4 mb-4">
                    <div className="row">
                      <div className="col-sm-12 mb-4 mt-5">
                        <h5 className="head-text-payment">
                          Ingrese los detalles del paquete
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Label>
                            <b>Nombre del Paquete</b>
                          </Label>
                          <Field
                            name="packageName"
                            type="text"
                            className="form-control"
                            placeholder="Nombre del Paquete"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="packageName">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Label>
                            <b>Valor vencido del pago ($)</b>
                          </Label>
                          <Field
                            name="packagePrice"
                            type="text"
                            className="form-control"
                            placeholder="Valor vencido del pago ($)"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="packagePrice">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Label>
                            <b>Invoice Number</b>
                          </Label>
                          <Field
                            name="invoiceNumber"
                            type="text"
                            className="form-control"
                            placeholder="Invoice Number"
                            disabled={true}
                          />
                          <ErrorMessage name="invoiceNumber">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="language"
                            as="select"
                            className="form-control"
                            placeholder="Número de Adultos"
                            disabled={isLoading}
                          >
                            <option value="es">Spanish</option>
                            <option value="en">English</option>
                          </Field>
                          <ErrorMessage name="adultsNumber">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-8 mb-4 mt-5">
                    <div className="row">
                      <div className="col-sm-12 mb-4">
                        <h5 className="head-text-payment">
                          Ingrese los detalles del cliente
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="firstName"
                            type="text"
                            className="form-control"
                            placeholder="Primer nombre"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="firstName">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="lastName"
                            type="text"
                            className="form-control"
                            placeholder="Apellido"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="lastName">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="Correo electrónico"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="email">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="phone"
                            type="phone"
                            className="form-control"
                            placeholder="Número de teléfono"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="phone">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    {isEmailSentSuccess && (
                      <div className="row">
                        <div className="col text-center">
                          <>
                            <Alert color="success">
                              <p>Successfully mail Sent</p>
                              <hr />
                              <p className="mb-0">
                                Payment request was successfully sent to the{" "}
                                <b>{invoiceSentData.email}</b>
                              </p>
                              <p>
                                Invoice Number:{" "}
                                <b>{invoiceSentData.invoiceNumber}</b>{" "}
                              </p>
                            </Alert>
                          </>
                        </div>
                      </div>
                    )}
                    {isFailSubmission && (
                      <div className="container">
                        <div className="row">
                          <div className="col text-center">
                            <>
                              <Alert color="danger">
                                {/* <h4 className="alert-heading">Error!</h4> */}
                                <p>{errorMsg.head}</p>
                                <hr />
                                <p className="mb-0">{errorMsg.sub}</p>
                              </Alert>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col text-center">
                          <button
                            type="submit"
                            className="submit-payment btn"
                            disabled={isLoading}
                          >
                            Send Payment Request
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="row">
                        <div className="col text-center mt-3">
                          <div
                            className="back-btn"
                            onClick={() => backToPayment()}
                          >
                            Back to Payment
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentInvoiceNoHaveForm;
