import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, FormGroup, Label } from "reactstrap";

import { checkoutPayment } from "../../../api/checkoutAPI";
import { getPaymentRequestById } from "../../../api/PaymentRequestAPI";

import "./PaymentPageSpanish.css";
import ThankYouPage from "../ThankYouPage/ThankYouPage";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const PaymentPageSpanish = ({ paymentToken }) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isFailDataRetrieve, setIsFailDataRetrieve] = useState(false);
  const [paymentRequestDetails, setPaymentRequestDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    packageName: "",
    invoiceNumber: "",
    price: "",
    language: "es",
  });
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(false);

  function successSubmission(data) {
    setIsFailSubmission(false);
    setIsPaymentSuccess(true);
    setIsLoading(false);

    setInvoiceDetails(data);
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  function successPaymentRequest(data) {
    console.log(data);
    setPaymentRequestDetails({ ...data });
  }

  function failDataRetrieve(head, sub) {
    setIsFailDataRetrieve(true);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  useEffect(() => {
    if (paymentToken) {
      getPaymentRequestById({
        paymentId: paymentToken,
      }).then((data) => {
        try {
          if (data["result"]) {
            if (data["result"] === "SUCCESS") {
              successPaymentRequest(data["data"]);
            } else if (data["result"] === "FAILED") {
              //failDataRetrieve("¡Error!", data["data"]["errorMessage"]);
              failDataRetrieve(
                "¡Error!",
                "Póngase en contacto con nuestro equipo, ¡tal vez esta solicitud de pago esté vencida!"
              );
            } else {
              failDataRetrieve(
                "¡Error!",
                "¡Por favor, inténtelo de nuevo más tarde!"
              );
            }
          } else {
            failDataRetrieve(
              "¡Error!",
              "¡Por favor, inténtelo de nuevo más tarde!"
            );
          }
        } catch (error) {
          if (data) {
            if (data["result"]) {
              if (data["result"] === "FAILED") {
                //console.log(data["data"]);
                failDataRetrieve("¡Error!", data["data"]["errorMessage"]);
              } else {
                failDataRetrieve(
                  "¡Error!",
                  "¡Por favor, inténtelo de nuevo más tarde!"
                );
              }
            } else {
              failDataRetrieve(
                "¡Error!",
                "¡Por favor, inténtelo de nuevo más tarde!"
              );
            }
          } else {
            failDataRetrieve(
              "¡Error!",
              "¡Por favor, inténtelo de nuevo más tarde!"
            );
          }
        }
      });
    } else {
      window.location.href = "https://vacationcoupon.com/";
    }
  }, [paymentToken]);

  return (
    <>
      {!isPaymentSuccess && (
        <>
          {!isFailDataRetrieve && (
            <div className="container mt-5 mb-5">
              <div className="row lap-padding-5">
                <div className="col-sm-4">
                  <div className="pl-3 pr-3 pb-3">
                    <div className="row pay-overview-head pay-overview-div">
                      <div className="col-sm-12 p-4">
                        <h5 className="font-fm">
                          {paymentRequestDetails.packageName}
                        </h5>
                      </div>
                    </div>
                    <div className="row pay-overview-div num-person-div">
                      <div className="col-6 p-4">
                        <p className="font-fm">Número de Factura</p>
                        <p className="font-fm">Monto de pago</p>
                      </div>
                      <div className="col-6 p-4">
                        <p className="font-fm">
                          {paymentRequestDetails.invoiceNumber}
                        </p>
                        <p className="font-fm">
                          ${paymentRequestDetails.packagePrice}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="payment-div">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        firstName: paymentRequestDetails.firstName,
                        lastName: paymentRequestDetails.lastName,
                        email: paymentRequestDetails.email,
                        phone: paymentRequestDetails.phoneNumber,
                        streetAddress: "",
                        city: "",
                        state: "",
                        country: "",
                        postalCode: "",
                        cardNumber: "",
                        cvc: "",
                        cardYear: "",
                        cardMonth: "",
                      }}
                      validationSchema={Yup.object({
                        firstName: Yup.string()
                          .min(4, "Debe tener 4 caracteres o más")
                          .max(50, "Debe tener 50 caracteres o menos")
                          .required("Requerido"),
                        lastName: Yup.string()
                          .min(4, "Debe tener 4 caracteres o más")
                          .max(50, "Debe tener 50 caracteres o menos")
                          .required("Requerido"),
                        email: Yup.string()
                          .max(100, "Debe tener 100 caracteres o menos")
                          .email("Dirección de correo electrónico inválida")
                          .required("Requerido"),
                        phone: Yup.string()
                          .matches(
                            phoneRegExp,
                            "El número de teléfono no es válido"
                          )
                          .required("Requerido"),
                        streetAddress: Yup.string()
                          .min(4, "Debe tener 4 caracteres o más")
                          .max(60, "Debe tener 60 caracteres o menos")
                          .required("Requerido"),
                        city: Yup.string()
                          .min(4, "Debe tener 4 caracteres o más")
                          .max(40, "Debe tener 40 caracteres o menos")
                          .required("Requerido"),
                        state: Yup.string()
                          .min(4, "Debe tener 4 caracteres o más")
                          .max(40, "Debe tener 40 caracteres o menos")
                          .required("Requerido"),
                        country: Yup.string().required("Requerido"),
                        postalCode: Yup.string()
                          .min(4, "Debe tener 4 caracteres o más")
                          .max(20, "Debe tener 20 caracteres o menos")
                          .required("Requerido"),
                        cardNumber: Yup.string()
                          .min(10, "Debe tener 10 caracteres o más")
                          .max(20, "Debe tener 20 caracteres o menos")
                          .required("Requerido"),
                        cvc: Yup.string()
                          .min(2, "Debe tener 2 caracteres o más")
                          .max(6, "Debe tener 6 caracteres o menos")
                          .required("Requerido"),
                        cardYear: Yup.string().required("Requerido"),
                        cardMonth: Yup.string().required("Requerido"),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        setIsLoading(true);
                        setIsFailSubmission(false);

                        let expireDateSet =
                          values.cardYear + "-" + values.cardMonth;
                        checkoutPayment({
                          amount: paymentRequestDetails.packagePrice,
                          cardNumber: values.cardNumber,
                          expireDate: expireDateSet,
                          cardCvv: values.cvc,
                          invoiceDescription: paymentRequestDetails.packageName,
                          firstName: values.firstName,
                          lastName: values.lastName,
                          address: values.streetAddress,
                          phoneNumber: values.phone,
                          city: values.city,
                          state: values.state,
                          zipCode: values.postalCode,
                          country: values.country,
                          email: values.email,
                          invoiceNumber: paymentRequestDetails.invoiceNumber,
                          language: "es",
                        }).then((data) => {
                          try {
                            if (data["result"]) {
                              if (data["result"] === "SUCCESS") {
                                successSubmission(data["data"]);
                              } else if (data["result"] === "FAILED") {
                                failSubmission(
                                  "¡Error!",
                                  data["data"]["errorMessage"]
                                );
                              } else {
                                failSubmission(
                                  "¡Error!",
                                  "¡Por favor, inténtelo de nuevo más tarde!"
                                );
                              }
                            } else {
                              failSubmission(
                                "¡Error!",
                                "¡Por favor, inténtelo de nuevo más tarde!"
                              );
                            }
                          } catch (error) {
                            if (data) {
                              if (data["result"]) {
                                if (data["result"] === "FAILED") {
                                  //console.log(data["data"]);
                                  failSubmission(
                                    "¡Error!",
                                    data["data"]["errorMessage"]
                                  );
                                } else {
                                  failSubmission(
                                    "¡Error!",
                                    "¡Por favor, inténtelo de nuevo más tarde!"
                                  );
                                }
                              } else {
                                failSubmission(
                                  "¡Error!",
                                  "¡Por favor, inténtelo de nuevo más tarde!"
                                );
                              }
                            } else {
                              failSubmission(
                                "¡Error!",
                                "¡Por favor, inténtelo de nuevo más tarde!"
                              );
                            }
                          }
                        });
                      }}
                    >
                      <Form>
                        <div className="row">
                          <div className="col-sm-12 mt-4">
                            <h5 className="head-text-payment">
                              Ingrese su información de contacto
                            </h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="firstName"
                                type="text"
                                className="form-control"
                                placeholder="Primer nombre"
                                disabled
                              />
                              <ErrorMessage name="firstName">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="lastName"
                                type="text"
                                className="form-control"
                                placeholder="Apellido"
                                disabled
                              />
                              <ErrorMessage name="lastName">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="email"
                                type="email"
                                className="form-control"
                                placeholder="Correo electrónico"
                                disabled
                              />
                              <ErrorMessage name="email">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="phone"
                                type="phone"
                                className="form-control"
                                placeholder="Número de teléfono"
                                disabled
                              />
                              <ErrorMessage name="phone">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mt-4">
                            <h5 className="head-text-payment">
                              Ingrese la información de facturación
                            </h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="streetAddress"
                                type="text"
                                className="form-control"
                                placeholder="Dirección"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="streetAddress">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="city"
                                type="text"
                                className="form-control"
                                placeholder="Ciudad"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="city">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="state"
                                type="text"
                                className="form-control"
                                placeholder="Estado"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="state">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="postalCode"
                                type="text"
                                className="form-control"
                                placeholder="Código Postal"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="postalCode">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="country"
                                as="select"
                                className="form-control"
                                placeholder="País"
                                disabled={isLoading}
                              >
                                <option selected value="">
                                  Seleccionar país
                                </option>
                                <option value="ar">Argentina</option>
                                <option value="bo">Bolivia</option>
                                <option value="br">Brazil</option>
                                <option value="cl">Chile</option>
                                <option value="co">Colombia</option>
                                <option value="cr">Costa Rica</option>
                                <option value="ec">Ecuador</option>
                                <option value="sv">El Salvador</option>
                                <option value="gt">Guatemala</option>
                                <option value="us">Estado Unido</option>
                                <option value="mx">México</option>
                                <option value="pa">Panama</option>
                                <option value="py">Paraguay</option>
                                <option value="pe">Perú</option>
                                <option value="uy">Uruguay</option>
                              </Field>
                              <ErrorMessage name="country">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mt-4">
                            <h5 className="head-text-payment">
                              Introduzca Información del pago
                            </h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-8">
                            <FormGroup className="input-payment-box">
                              <Label for="cardNumber">
                                Número de Tarjeta de Crédito:
                              </Label>
                              <Field
                                name="cardNumber"
                                type="number"
                                className="form-control"
                                placeholder="Número de tarjeta"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="cardNumber">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                          <div className="col-sm-4">
                            <FormGroup className="input-payment-box">
                              <Label for="cvc">Código CVC:</Label>
                              <Field
                                name="cvc"
                                type="number"
                                className="form-control"
                                placeholder="CVC"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="cvc">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Label for="cardMonth">
                                Meses de vencimiento:
                              </Label>
                              <Field
                                name="cardMonth"
                                as="select"
                                className="form-control"
                                disabled={isLoading}
                              >
                                <option selected value="">
                                  Meses de vencimiento
                                </option>
                                <option value="01">01</option>
                                <option value="02">02</option>
                                <option value="03">03</option>
                                <option value="04">04</option>
                                <option value="05">05</option>
                                <option value="06">06</option>
                                <option value="07">07</option>
                                <option value="08">08</option>
                                <option value="09">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                              </Field>
                              <ErrorMessage name="cardMonth">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Label for="cardYear">Año de caducidad:</Label>
                              <Field
                                name="cardYear"
                                as="select"
                                className="form-control"
                                disabled={isLoading}
                              >
                                <option selected value="">
                                  Año de caducidad
                                </option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                                <option value="2031">2031</option>
                              </Field>
                              <ErrorMessage name="cardYear">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        {isFailSubmission && (
                          <div className="container">
                            <div className="row">
                              <div className="col text-center">
                                <>
                                  <Alert color="danger">
                                    {/* <h4 className="alert-heading">Error!</h4> */}
                                    <p>{errorMsg.head}</p>
                                    <hr />
                                    <p className="mb-0">{errorMsg.sub}</p>
                                  </Alert>
                                </>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="container">
                          <div className="row mt-2">
                            <div className="col text-center">
                              <button
                                type="submit"
                                className="submit-payment btn"
                                disabled={isLoading}
                              >
                                Pagar Ahora ($
                                {paymentRequestDetails.packagePrice})
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="container">
                          <div className="row mt-4">
                            <div className="col text-center">
                              <img
                                className="payment-img"
                                src="/images/payment/payment.webp"
                                alt="payment"
                              ></img>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isFailDataRetrieve && (
            <div className="container">
              <div className="row mt-5">
                <div className="col text-center">
                  <>
                    <Alert color="danger">
                      {/* <h4 className="alert-heading">Error!</h4> */}
                      <p>{errorMsg.head}</p>
                      <hr />
                      <p className="mb-0">{errorMsg.sub}</p>
                    </Alert>
                  </>
                </div>
              </div>
              {/* <div className="row mt-5">
            <div className="col text-center">
              <Link to="/invoice-generator">Back to Invoice Generator</Link>
            </div>
          </div> */}
            </div>
          )}
        </>
      )}
      {isPaymentSuccess && (
        <ThankYouPage
          invoiceDetails={invoiceDetails}
          customerDetails={paymentRequestDetails}
          language="es"
        />
      )}
    </>
  );
};

export default PaymentPageSpanish;
