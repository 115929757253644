import axios from "axios";

export const paymentRequestCreate = async ({
  firstName,
  lastName,
  email,
  phoneNumber,
  packageName,
  packagePrice,
  invoiceNumber,
  language,
}) => {
  try {
    let formData = new FormData();
    const token = localStorage.getItem("vc-auth-token");

    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("phoneNumber", phoneNumber);
    formData.append("packageName", packageName);
    formData.append("packagePrice", packagePrice);
    formData.append("invoiceNumber", invoiceNumber);
    formData.append("language", language);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/payment_end/request_create.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const getPaymentRequestById = async ({ paymentId }) => {
  try {
    let formData = new FormData();

    formData.append("payment_request_id", paymentId);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/payment_end/read_by_id.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readAllPaymentRequest = async () => {
  try {
    const token = localStorage.getItem("vc-auth-token");
    let formData = new FormData();

    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/payment_end/read_all.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
