import axios from "axios";

export const invoiceCreate = async ({
  firstName,
  lastName,
  email,
  phoneNumber,
  address,
  city,
  state,
  zipCode,
  country,
  packageName,
  packagePrice,
  vatValue,
  adultsNumber,
  kidsNumber,
  language,
  status,
  invoiceDescription,
}) => {
  try {
    const token = localStorage.getItem("vc-auth-token");
    let formData = new FormData();

    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("phoneNumber", phoneNumber);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("zipCode", zipCode);
    formData.append("country", country);
    formData.append("packageName", packageName);
    formData.append("packagePrice", packagePrice);
    formData.append("vatValue", vatValue);
    formData.append("adultsNumber", adultsNumber);
    formData.append("kidsNumber", kidsNumber);
    formData.append("language", language);
    formData.append("status", status);
    formData.append("invoiceDescription", invoiceDescription);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/invoice/create.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const getInvoiceById = async ({ invoiceId }) => {
  try {
    let formData = new FormData();

    formData.append("invoice_unique_id", invoiceId);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/invoice/read_by_id.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const sendInvoiceEmail = async ({ invoiceId }) => {
  try {
    const token = localStorage.getItem("vc-auth-token");
    let formData = new FormData();

    formData.append("invoiceUniqueId", invoiceId);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/invoice/send_invoice_mail.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const getInvoiceByInvoiceNumber = async ({ invoiceNumber }) => {
  try {
    let formData = new FormData();

    formData.append("invoice_number", invoiceNumber);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/invoice/read_by_invoice_number.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readAllInvoices = async () => {
  try {
    const token = localStorage.getItem("vc-auth-token");
    let formData = new FormData();

    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/invoice/read_all.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
