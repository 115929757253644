import React from "react";
import { Redirect, useLocation, useParams } from "react-router";

import InvoiceEnglish from "./InvoiceEnglish/InvoiceEnglish";
import InvoiceSpanish from "./InvoiceSpanish/InvoiceSpanish";

const InvoiceGenerator = ({ match }) => {
  let { invoiceId, languageId } = useParams();

  const renderPage = () => {
    switch (languageId) {
      case "en":
        return <InvoiceEnglish invoiceId={invoiceId} />;
      case "es":
        return <InvoiceSpanish invoiceId={invoiceId} />;
      default:
        return <Redirect from="/:url*(/+)" to="/invoice-generator" />;
    }
  };

  return <>{renderPage()}</>;
};

export default InvoiceGenerator;
