import React, { useEffect, useState } from "react";
import { Container, Navbar, NavDropdown } from "react-bootstrap";
import { Link, Redirect, Route } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";

import { tokenCheck } from "../../api/authAPI";
import EditUser from "../../RootPage/auth/editUser/EditUser";

import "./ProtectedRoute.css";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const [open, setOpen] = useState(false);
  const isAuthenticated = localStorage.getItem("vc-is-valid-auth");

  useEffect(() => {
    const token = localStorage.getItem("vc-auth-token");
    if (token) {
      tokenCheck({
        token: token,
      }).then((data) => {
        try {
          if (data["result"]) {
            if (data["result"] === "SUCCESS") {
              localStorage.setItem("vc-is-valid-auth", true);
            } else if (data["result"] === "FAILED") {
              localStorage.setItem("vc-is-valid-auth", false);
              window.location.href = "/login";
            } else {
              localStorage.setItem("vc-is-valid-auth", false);
              window.location.href = "/login";
            }
          } else {
            localStorage.setItem("vc-is-valid-auth", false);
            window.location.href = "/login";
          }
        } catch (error) {
          localStorage.setItem("vc-is-valid-auth", false);
          window.location.href = "/login";
        }
      });
    } else {
      localStorage.setItem("vc-is-valid-auth", false);
      window.location.href = "/login";
    }
  });

  const logOutClick = () => {
    localStorage.removeItem("vc-auth-firstName");
    localStorage.removeItem("vc-auth-lastName");
    localStorage.removeItem("vc-auth-email");
    localStorage.removeItem("vc-auth-role");
    localStorage.removeItem("vc-auth-token");
    localStorage.removeItem("vc-is-valid-auth");

    window.location.href = "/login";
  };

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? (
          <>
            <Navbar bg="light" variant="light">
              <Container>
                <Navbar.Brand href="/">Vacation Coupon App</Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                  <NavDropdown
                    title={`Agent Name: ${localStorage.getItem(
                      "vc-auth-firstName"
                    )}`}
                    className="logout-head"
                  >
                    <NavDropdown.Item onClick={() => setOpen(!open)}>
                      Edit Profile
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={logOutClick}>
                      Log Out
                    </NavDropdown.Item>
                  </NavDropdown>
                </Navbar.Collapse>
              </Container>
            </Navbar>
            <Menu
              className={
                open
                  ? "profile-bar-menu"
                  : "profile-bar-menu profile-bar-menu-hide"
              }
              fixed="right"
              borderless
              vertical
            >
              <div className={open ? "" : "hide-menu-content"}>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="close-icon" onClick={() => setOpen(!open)}>
                      <Icon name="arrow alternate circle right outline" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mb-5">
                    <EditUser />
                  </div>
                </div>
              </div>
            </Menu>
            <Component {...props} />
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default ProtectedRoute;
