import axios from "axios";

export const checkoutPayment = async ({
  amount,
  cardNumber,
  expireDate,
  cardCvv,
  invoiceDescription,
  firstName,
  lastName,
  address,
  phoneNumber,
  city,
  state,
  zipCode,
  country,
  email,
  invoiceNumber,
  language,
}) => {
  try {
    let formData = new FormData();

    formData.append("amount", amount);
    formData.append("cardNumber", cardNumber);
    formData.append("expireDate", expireDate);
    formData.append("cardCvv", cardCvv);
    formData.append("invoiceDescription", invoiceDescription);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("address", address);
    formData.append("phoneNumber", phoneNumber);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("zipCode", zipCode);
    formData.append("country", country);
    formData.append("email", email);
    formData.append("invoiceNumber", invoiceNumber);
    formData.append("language", language);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/payment/authorizenet_credit_card.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
