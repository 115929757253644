import { useMemo } from "react";
import { useEffect, useState } from "react";

import { HiViewGrid } from "react-icons/hi";
import DataTable from "react-data-table-component";
import { readAllPaymentRequest } from "../../../api/PaymentRequestAPI";

import "./AllPaymentRequest.css";

const AllPaymentRequest = () => {
  const [paymentReqItems, setPaymentReqItems] = useState([]);
  const [pending, setPending] = useState(true);
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    readAllPaymentRequest().then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setPaymentReqItems(data["data"]);
            setFilteredItems(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        if (data) {
          if (data["result"]) {
            if (data["result"] === "FAILED") {
              setPending(true);
            } else {
              setPending(true);
            }
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      }
    });
  }, [paymentReqItems.length]);

  const returnStatus = (status) => {
    switch (status) {
      case "paid":
        return (
          <h3>
            <span className="badge rounded-pill bg-success lg">Paid</span>
          </h3>
        );
      case "fail_paid":
        return (
          <h3>
            <span className="badge rounded-pill bg-danger lg">Fail Paid</span>
          </h3>
        );
      case "unknown_error":
        return (
          <h3>
            <span className="badge rounded-pill bg-warning lg">Unknown</span>
          </h3>
        );
      case "not_paid":
        return (
          <h3>
            <span className="badge rounded-pill bg-info lg">Pending</span>
          </h3>
        );
      default:
        return (
          <h3>
            <span className="badge rounded-pill bg-secondary lg">-</span>
          </h3>
        );
    }
  };

  const columns = useMemo(
    () => [
      {
        name: "Invoice Number",
        selector: (row) => row.invoiceNumber,
        sortable: true,
        grow: 3,
      },
      {
        name: "First Name",
        selector: (row) => row.firstName,
        sortable: true,
      },
      {
        name: "Last Name",
        selector: (row) => row.lastName,
        sortable: true,
      },
      {
        name: "Phone Number",
        selector: (row) => row.phoneNumber,
        sortable: true,
        grow: 2,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
        grow: 2,
      },
      {
        name: "Price($)",
        selector: (row) => row.packagePrice,
        sortable: true,
      },
      {
        name: "Responsible",
        selector: (row) => row.responsible,
        sortable: true,
        grow: 2,
      },
      {
        name: "Status",
        selector: (row) => returnStatus(row.status),
        grow: 2,
      },
      {
        name: "Created",
        selector: (row) => row.created,
        sortable: true,
        grow: 2,
      },
    ],
    []
  );

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        fontSize: "16px",
      },
    },
  };

  const searchOnChange = (e) => {
    setFilteredItems(
      paymentReqItems.filter((item) => {
        return (
          item.invoiceNumber &&
          item.email &&
          item.firstName &&
          item.lastName &&
          (item.invoiceNumber
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
            item.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item.firstName
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item.lastName.toLowerCase().includes(e.target.value.toLowerCase()))
        );
      })
    );
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <DataTable
            title="Payment Request"
            data={filteredItems}
            columns={columns}
            fixedHeader
            fixedHeaderScrollHeight="100%"
            pagination
            subHeader
            subHeaderComponent={
              <input
                type="text"
                className="form-control"
                placeholder="Search by Invoice Number, Email, First Name, Last Name"
                onChange={(e) => searchOnChange(e)}
              />
            }
            progressPending={pending}
            customStyles={customStyles}
          />
        </div>
      </div>
    </div>
  );
};

export default AllPaymentRequest;
