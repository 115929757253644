import React from "react";
import { Redirect, useLocation, useParams } from "react-router";
import PaymentPageEnglish from "./PaymentPageEnglish/PaymentPageEnglish";

import PaymentPageSpanish from "./PaymentPageSpanish/PaymentPageSpanish";

const PaymentGenerator = ({ match }) => {
  let { paymentToken, languageId } = useParams();

  const renderPage = () => {
    switch (languageId) {
      case "en":
        return <PaymentPageEnglish paymentToken={paymentToken} />;
      case "es":
        return <PaymentPageSpanish paymentToken={paymentToken} />;
      default:
        return <Redirect from="/:url*(/+)" to="/payment-generator" />;
    }
  };

  return <>{renderPage()}</>;
};

export default PaymentGenerator;
