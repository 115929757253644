import React from "react";

import {
  FaFileInvoice,
  FaPaypal,
  FaUserAlt,
  FaFileInvoiceDollar,
} from "react-icons/fa";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { Link } from "react-router-dom";

import "./homePage.css";

function HomePage() {
  return (
    <div className="container">
      <div className="row mt-5 mb-5">
        <div className="col-sm-12">
          <div>
            <div className="row d-flex justify-content-center">
              <div className="col-sm-4">
                <div className="card-h-div p-4 mb-4">
                  <Link to="/all-invoices">
                    <FaFileInvoiceDollar size="50" />
                    <h3>All Invoices</h3>
                    <p>Get all invoice details which we sent to the clients.</p>
                  </Link>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="card-h-div p-4 mb-4">
                  <Link to="/invoice-generator">
                    <FaFileInvoice size="50" />
                    <h3>Invoice Generator</h3>
                    <p>You can automatically generate an invoice from here.</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-sm-3">
                <div className="card-h-div p-4 mb-4">
                  <Link to="/all-payment-request">
                    <FaPaypal size="50" />
                    <h3>All Payment Request</h3>
                    <p>
                      Get all payment request details which we sent to the
                      clients.
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="card-h-div p-4 mb-4">
                  <Link to="/all-checkouts">
                    <FaPaypal size="50" />
                    <h3>All Checkouts</h3>
                    <p>Get all Payment history details which clients done.</p>
                  </Link>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="card-h-div p-4 mb-4">
                  <Link to="/payment-generator">
                    <FaPaypal size="50" />
                    <h3>Payment Link Send</h3>
                    <p>You can send a payment link to your customer to pay.</p>
                  </Link>
                </div>
              </div>
            </div>
            {localStorage.getItem("vc-auth-role") === "admin" && (
              <div className="row d-flex justify-content-center">
                <div className="col-sm-4">
                  <div className="card-h-div p-4 mb-4">
                    <Link to="/all-users">
                      <FaUserAlt size="50" />
                      <h3>All Users</h3>
                      <p>Get all user details which is in our system.</p>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card-h-div p-4 mb-4">
                    <Link to="/sign-in">
                      <AiOutlineUsergroupAdd size="50" />
                      <h3>Create User</h3>
                      <p>You can create a new user for the system</p>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
