import React, { useState } from "react";
import { Link } from "react-router-dom";
import PaymentInvoiceHaveForm from "../PaymentInvoiceHaveForm/PaymentInvoiceHaveForm";
import PaymentInvoiceNoHaveForm from "../PaymentInvoiceNoHaveForm/PaymentInvoiceNoHaveForm";

import "./Payment.css";

const Payment = () => {
  const [currentPage, setCurrentPage] = useState();
  const [invoiceHave, setInvoiceHave] = useState();
  const [paymentDetails, setPaymentDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    packageName: "",
    invoiceNumber: "",
    price: "",
    language: "es",
  });

  const successInvoiceDataRequest = (data) => {
    let priceWithVat = (
      parseFloat(data.vatValue) * 0.01 * parseFloat(data.packagePrice) +
      parseFloat(data.packagePrice)
    ).toFixed(2);

    setPaymentDetails(
      {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        packageName: data.packageName,
        price: priceWithVat,
        invoiceNumber: data.invoiceNumber,
        language: data.language,
      },
      setCurrentPage("NO_INVOICE")
    );
  };

  const backToPayment = () => {
    setPaymentDetails(
      {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        packageName: "",
        invoiceNumber: "",
        price: "",
        language: "es",
      },
      setCurrentPage("BASE")
    );
  };

  const renderPage = () => {
    switch (currentPage) {
      case "HAVE_INVOICE":
        return (
          <PaymentInvoiceHaveForm
            backToPayment={backToPayment}
            successInvoiceDataRequest={successInvoiceDataRequest}
          />
        );
      case "NO_INVOICE":
        return (
          <PaymentInvoiceNoHaveForm
            backToPayment={backToPayment}
            paymentDetails={paymentDetails}
          />
        );
      default:
        return (
          <div className="container">
            <div className="row mt-5">
              <div className="col-sm-12">
                <h3 className="step-question-p-style">
                  Do you have a past invoice number?
                </h3>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12">
                <div className="container">
                  <div className="row mt-4 d-flex justify-content-center">
                    <div
                      onClick={() => {
                        setCurrentPage("HAVE_INVOICE");
                        setInvoiceHave(true);
                      }}
                      className={
                        invoiceHave === true
                          ? "col-sm-4 button-global-style button-global-style-active"
                          : "col-sm-4 button-global-style"
                      }
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.81055 14.8005L11.2227 17.2132L15.7343 12.7016"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.27148 8.34631C3.27148 6.68946 4.61463 5.34631 6.27148 5.34631H18.2715C19.9283 5.34631 21.2715 6.68946 21.2715 8.34631V18.3463C21.2715 20.0032 19.9283 21.3463 18.2715 21.3463H6.27148C4.61463 21.3463 3.27148 20.0032 3.27148 18.3463V8.34631Z"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.37891 9.23853H21.1113"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.27148 3.84631V6.84631"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16.2715 3.84631V6.84631"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <p className="button-global-style-p">Yes</p>
                    </div>
                    <div
                      onClick={() => {
                        setCurrentPage("NO_INVOICE");
                        setInvoiceHave(false);
                      }}
                      className={
                        invoiceHave === false
                          ? "col-sm-4 button-global-style button-global-style-active"
                          : "col-sm-4 button-global-style"
                      }
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.79688 17.5977L14.7466 12.648"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.79688 12.648L14.7466 17.5978"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.27148 8.34631C3.27148 6.68946 4.61463 5.34631 6.27148 5.34631H18.2715C19.9283 5.34631 21.2715 6.68946 21.2715 8.34631V18.3463C21.2715 20.0032 19.9283 21.3463 18.2715 21.3463H6.27148C4.61463 21.3463 3.27148 20.0032 3.27148 18.3463V8.34631Z"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          opacity="0.5"
                          d="M3.37891 9.23853H21.1113"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.27148 3.84631V6.84631"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16.2715 3.84631V6.84631"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <p className="button-global-style-p">No</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col text-center mt-3">
                  <Link className="back-btn" to="/">
                    Back to Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div>{renderPage()}</div>
    </>
  );
};

export default Payment;
