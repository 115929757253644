import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, FormGroup, Label } from "reactstrap";

import { checkoutPayment } from "../../../api/checkoutAPI";
import { getPaymentRequestById } from "../../../api/PaymentRequestAPI";
import ThankYouPage from "../ThankYouPage/ThankYouPage";

import "./PaymentPageEnglish.css";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const PaymentPageEnglish = ({ paymentToken }) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isFailDataRetrieve, setIsFailDataRetrieve] = useState(false);
  const [paymentRequestDetails, setPaymentRequestDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    packageName: "",
    invoiceNumber: "",
    price: "",
    language: "es",
  });
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(false);

  function successSubmission(data) {
    setIsFailSubmission(false);
    setIsPaymentSuccess(true);
    setIsLoading(false);

    setInvoiceDetails(data);
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  function successPaymentRequest(data) {
    console.log(data);
    setPaymentRequestDetails({ ...data });
  }

  function failDataRetrieve(head, sub) {
    setIsFailDataRetrieve(true);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  useEffect(() => {
    if (paymentToken) {
      getPaymentRequestById({
        paymentId: paymentToken,
      }).then((data) => {
        try {
          if (data["result"]) {
            if (data["result"] === "SUCCESS") {
              successPaymentRequest(data["data"]);
            } else if (data["result"] === "FAILED") {
              //failDataRetrieve("Error!", data["data"]["errorMessage"]);
              failDataRetrieve(
                "Error!",
                "Contact our Team, Maybe this payment request is expired!"
              );
            } else {
              failDataRetrieve("Error!", "Please try again later!");
            }
          } else {
            failDataRetrieve("Error!", "Please try again later!");
          }
        } catch (error) {
          if (data) {
            if (data["result"]) {
              if (data["result"] === "FAILED") {
                //console.log(data["data"]);
                failDataRetrieve("Error!", data["data"]["errorMessage"]);
              } else {
                failDataRetrieve("Error!", "Please try again later!");
              }
            } else {
              failDataRetrieve("Error!", "Please try again later!");
            }
          } else {
            failDataRetrieve("Error!", "Please try again later!");
          }
        }
      });
    } else {
      window.location.href = "https://vacationcoupon.com/";
    }
  }, [paymentToken]);

  return (
    <>
      {!isPaymentSuccess && (
        <>
          {!isFailDataRetrieve && (
            <div className="container mt-5 mb-5">
              <div className="row lap-padding-5">
                <div className="col-sm-4">
                  <div className="pl-3 pr-3 pb-3">
                    <div className="row pay-overview-head pay-overview-div">
                      <div className="col-sm-12 p-4">
                        <h5 className="font-fm">
                          {paymentRequestDetails.packageName}
                        </h5>
                      </div>
                    </div>
                    <div className="row pay-overview-div num-person-div">
                      <div className="col-6 p-4">
                        <p className="font-fm">Invoice Number</p>
                        <p className="font-fm">Total Price</p>
                      </div>
                      <div className="col-6 p-4">
                        <p className="font-fm">
                          {paymentRequestDetails.invoiceNumber}
                        </p>
                        <p className="font-fm">
                          ${paymentRequestDetails.packagePrice}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="payment-div">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        firstName: paymentRequestDetails.firstName,
                        lastName: paymentRequestDetails.lastName,
                        email: paymentRequestDetails.email,
                        phone: paymentRequestDetails.phoneNumber,
                        streetAddress: "",
                        city: "",
                        state: "",
                        country: "",
                        postalCode: "",
                        cardNumber: "",
                        cvc: "",
                        cardYear: "",
                        cardMonth: "",
                      }}
                      validationSchema={Yup.object({
                        firstName: Yup.string()
                          .min(4, "Must be 4 characters or more")
                          .max(50, "Must be 50 characters or less")
                          .required("Required"),
                        lastName: Yup.string()
                          .min(4, "Must be 4 characters or more")
                          .max(50, "Must be 50 characters or less")
                          .required("Required"),
                        email: Yup.string()
                          .max(100, "Must be 100 characters or less")
                          .email("Invalid Email")
                          .required("Required"),
                        phone: Yup.string()
                          .matches(phoneRegExp, "invalid Phone Number")
                          .required("Required"),
                        streetAddress: Yup.string()
                          .min(4, "Must be 4 characters or more")
                          .max(60, "Must be 60 characters or less")
                          .required("Required"),
                        city: Yup.string()
                          .min(4, "Must be 4 characters or more")
                          .max(40, "Must be 40 characters or less")
                          .required("Required"),
                        state: Yup.string()
                          .min(4, "Must be 4 characters or more")
                          .max(40, "Must be 40 characters or less")
                          .required("Required"),
                        country: Yup.string().required("Required"),
                        postalCode: Yup.string()
                          .min(4, "Must be 4 characters or more")
                          .max(20, "Must be 20 characters or less")
                          .required("Required"),
                        cardNumber: Yup.string()
                          .min(10, "Must be 10 characters or more")
                          .max(20, "Must be 20 characters or less")
                          .required("Required"),
                        cvc: Yup.string()
                          .min(2, "Must be 2 characters or more")
                          .max(6, "Must be 6 characters or less")
                          .required("Required"),
                        cardYear: Yup.string().required("Required"),
                        cardMonth: Yup.string().required("Required"),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        setIsLoading(true);
                        setIsFailSubmission(false);

                        let expireDateSet =
                          values.cardYear + "-" + values.cardMonth;
                        checkoutPayment({
                          amount: paymentRequestDetails.packagePrice,
                          cardNumber: values.cardNumber,
                          expireDate: expireDateSet,
                          cardCvv: values.cvc,
                          invoiceDescription: paymentRequestDetails.packageName,
                          firstName: values.firstName,
                          lastName: values.lastName,
                          address: values.streetAddress,
                          phoneNumber: values.phone,
                          city: values.city,
                          state: values.state,
                          zipCode: values.postalCode,
                          country: values.country,
                          email: values.email,
                          invoiceNumber: paymentRequestDetails.invoiceNumber,
                          language: "en",
                        }).then((data) => {
                          try {
                            if (data["result"]) {
                              if (data["result"] === "SUCCESS") {
                                successSubmission(data["data"]);
                              } else if (data["result"] === "FAILED") {
                                failSubmission(
                                  "Error!",
                                  data["data"]["errorMessage"]
                                );
                              } else {
                                failSubmission(
                                  "Error!",
                                  "Please try again later!"
                                );
                              }
                            } else {
                              failSubmission(
                                "Error!",
                                "Please try again later!"
                              );
                            }
                          } catch (error) {
                            if (data) {
                              if (data["result"]) {
                                if (data["result"] === "FAILED") {
                                  //console.log(data["data"]);
                                  failSubmission(
                                    "Error!",
                                    data["data"]["errorMessage"]
                                  );
                                } else {
                                  failSubmission(
                                    "Error!",
                                    "Please try again later!"
                                  );
                                }
                              } else {
                                failSubmission(
                                  "Error!",
                                  "Please try again later!"
                                );
                              }
                            } else {
                              failSubmission(
                                "Error!",
                                "Please try again later!"
                              );
                            }
                          }
                        });
                      }}
                    >
                      <Form>
                        <div className="row">
                          <div className="col-sm-12 mt-4">
                            <h5 className="head-text-payment">
                              Enter your contact information
                            </h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="firstName"
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                disabled
                              />
                              <ErrorMessage name="firstName">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="lastName"
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                                disabled
                              />
                              <ErrorMessage name="lastName">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="email"
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                disabled
                              />
                              <ErrorMessage name="email">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="phone"
                                type="phone"
                                className="form-control"
                                placeholder="Phone Number"
                                disabled
                              />
                              <ErrorMessage name="phone">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mt-4">
                            <h5 className="head-text-payment">
                              Enter billing information
                            </h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="streetAddress"
                                type="text"
                                className="form-control"
                                placeholder="Street Number"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="streetAddress">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="city"
                                type="text"
                                className="form-control"
                                placeholder="City"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="city">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="state"
                                type="text"
                                className="form-control"
                                placeholder="State"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="state">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="postalCode"
                                type="text"
                                className="form-control"
                                placeholder="Postal Code"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="postalCode">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Field
                                name="country"
                                as="select"
                                className="form-control"
                                placeholder="Country"
                                disabled={isLoading}
                              >
                                <option selected value="">
                                  Select Country
                                </option>
                                <option value="ar">Argentina</option>
                                <option value="bo">Bolivia</option>
                                <option value="br">Brazil</option>
                                <option value="cl">Chile</option>
                                <option value="co">Colombia</option>
                                <option value="cr">Costa Rica</option>
                                <option value="ec">Ecuador</option>
                                <option value="sv">El Salvador</option>
                                <option value="gt">Guatemala</option>
                                <option value="us">United State</option>
                                <option value="mx">México</option>
                                <option value="pa">Panama</option>
                                <option value="py">Paraguay</option>
                                <option value="pe">Perú</option>
                                <option value="uy">Uruguay</option>
                              </Field>
                              <ErrorMessage name="country">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mt-4">
                            <h5 className="head-text-payment">
                              Enter Payment Information
                            </h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-8">
                            <FormGroup className="input-payment-box">
                              <Label for="cardNumber">
                                Credit card number:
                              </Label>
                              <Field
                                name="cardNumber"
                                type="number"
                                className="form-control"
                                placeholder="Card number"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="cardNumber">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                          <div className="col-sm-4">
                            <FormGroup className="input-payment-box">
                              <Label for="cvc">CVC Code:</Label>
                              <Field
                                name="cvc"
                                type="number"
                                className="form-control"
                                placeholder="CVC"
                                disabled={isLoading}
                              />
                              <ErrorMessage name="cvc">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Label for="cardMonth">Expiration months:</Label>
                              <Field
                                name="cardMonth"
                                as="select"
                                className="form-control"
                                disabled={isLoading}
                              >
                                <option selected value="">
                                  Expiration months
                                </option>
                                <option value="01">01</option>
                                <option value="02">02</option>
                                <option value="03">03</option>
                                <option value="04">04</option>
                                <option value="05">05</option>
                                <option value="06">06</option>
                                <option value="07">07</option>
                                <option value="08">08</option>
                                <option value="09">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                              </Field>
                              <ErrorMessage name="cardMonth">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                          <div className="col-sm-6">
                            <FormGroup className="input-payment-box">
                              <Label for="cardYear">Expiry year:</Label>
                              <Field
                                name="cardYear"
                                as="select"
                                className="form-control"
                                disabled={isLoading}
                              >
                                <option selected value="">
                                  Expiry year
                                </option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                                <option value="2031">2031</option>
                              </Field>
                              <ErrorMessage name="cardYear">
                                {(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </FormGroup>
                          </div>
                        </div>
                        {isFailSubmission && (
                          <div className="container">
                            <div className="row">
                              <div className="col text-center">
                                <>
                                  <Alert color="danger">
                                    {/* <h4 className="alert-heading">Error!</h4> */}
                                    <p>{errorMsg.head}</p>
                                    <hr />
                                    <p className="mb-0">{errorMsg.sub}</p>
                                  </Alert>
                                </>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="container">
                          <div className="row mt-2">
                            <div className="col text-center">
                              <button
                                type="submit"
                                className="submit-payment btn"
                                disabled={isLoading}
                              >
                                Pay Now ($
                                {paymentRequestDetails.packagePrice})
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="container">
                          <div className="row mt-4">
                            <div className="col text-center">
                              <img
                                className="payment-img"
                                src="/images/payment/payment.webp"
                                alt="payment"
                              ></img>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isFailDataRetrieve && (
            <div className="container">
              <div className="row mt-5">
                <div className="col text-center">
                  <>
                    <Alert color="danger">
                      {/* <h4 className="alert-heading">Error!</h4> */}
                      <p>{errorMsg.head}</p>
                      <hr />
                      <p className="mb-0">{errorMsg.sub}</p>
                    </Alert>
                  </>
                </div>
              </div>
              {/* <div className="row mt-5">
            <div className="col text-center">
              <Link to="/invoice-generator">Back to Invoice Generator</Link>
            </div>
          </div> */}
            </div>
          )}
        </>
      )}
      {isPaymentSuccess && (
        <ThankYouPage
          invoiceDetails={invoiceDetails}
          customerDetails={paymentRequestDetails}
          language="en"
        />
      )}
    </>
  );
};

export default PaymentPageEnglish;
