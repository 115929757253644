import { useMemo } from "react";
import { useEffect, useState } from "react";

import { HiViewGrid } from "react-icons/hi";
import DataTable from "react-data-table-component";
import { readAllInvoices } from "../../../api/invoiceAPI";

import "./ReadInvoice.css";

const ReadInvoice = () => {
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [pending, setPending] = useState(true);
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    readAllInvoices().then((data) => {
      try {
        if (data["result"]) {
          if (data["result"] === "SUCCESS") {
            setInvoiceItems(data["data"]);
            setFilteredItems(data["data"]);
            setPending(false);
          } else if (data["result"] === "FAILED") {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        if (data) {
          if (data["result"]) {
            if (data["result"] === "FAILED") {
              setPending(true);
            } else {
              setPending(true);
            }
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      }
    });
  }, [invoiceItems.length]);

  const columns = useMemo(
    () => [
      {
        name: "Invoice Number",
        selector: (row) => row.invoiceNumber,
        sortable: true,
        grow: 2,
      },
      {
        name: "First Name",
        selector: (row) => row.firstName,
        sortable: true,
      },
      {
        name: "Last Name",
        selector: (row) => row.lastName,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
        grow: 2,
      },
      {
        name: "Responsible",
        selector: (row) => row.responsible,
        sortable: true,
        grow: 2,
      },
      {
        name: "Status",
        selector: (row) =>
          row.status === "Pending" ? (
            <h3>
              <span className="badge rounded-pill bg-info lg">Pending</span>
            </h3>
          ) : (
            <h3>
              <span className="badge rounded-pill bg-success">Paid</span>
            </h3>
          ),
      },
      {
        name: "Created",
        selector: (row) => row.created,
        sortable: true,
        grow: 2,
      },
      {
        cell: (row) => (
          <a
            className="invoice-view-link"
            href={`/invoice-generator/${row.language}/${row.uniqueId}`}
            target="blank"
          >
            <HiViewGrid /> View
          </a>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    []
  );

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        fontSize: "16px",
      },
    },
  };

  const searchOnChange = (e) => {
    setFilteredItems(
      invoiceItems.filter((item) => {
        return (
          item.invoiceNumber &&
          item.email &&
          item.firstName &&
          item.lastName &&
          (item.invoiceNumber
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
            item.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item.firstName
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item.lastName.toLowerCase().includes(e.target.value.toLowerCase()))
        );
      })
    );
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <DataTable
            title="Invoices"
            data={filteredItems}
            columns={columns}
            fixedHeader
            fixedHeaderScrollHeight="100%"
            pagination
            subHeader
            subHeaderComponent={
              <input
                type="text"
                className="form-control"
                placeholder="Search by Invoice Number, Email, First Name, Last Name"
                onChange={(e) => searchOnChange(e)}
              />
            }
            progressPending={pending}
            customStyles={customStyles}
          />
        </div>
      </div>
    </div>
  );
};

export default ReadInvoice;
