import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, FormGroup, Label } from "reactstrap";

import "./InvoiceForm.css";
import { invoiceCreate } from "../../../api/invoiceAPI";
import { Link } from "react-router-dom";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const InvoiceForm = () => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);

  function successSubmission(data) {
    window.location.pathname = `/invoice-generator/${data["language"]}/${data["unique_id"]}`;
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  return (
    <div className="container">
      <div className="row mb-5">
        <div className="col-sm-12">
          <div className="invoice-form-div">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                streetAddress: "",
                city: "",
                state: "",
                country: "",
                postalCode: "",
                packageName: "",
                packagePrice: "",
                adultsNumber: 1,
                kidsNumber: 0,
                language: "es",
                invoiceDescription:
                  "Esto es válido solo dos semanas después de la fecha de emisión.",
                vatValue: "13.5",
                status: "Pending",
              }}
              validationSchema={Yup.object({
                firstName: Yup.string()
                  .min(4, "Debe tener 4 caracteres o más")
                  .max(50, "Debe tener 50 caracteres o menos")
                  .required("Requerido"),
                lastName: Yup.string()
                  .min(4, "Debe tener 4 caracteres o más")
                  .max(50, "Debe tener 50 caracteres o menos")
                  .required("Requerido"),
                email: Yup.string()
                  .max(100, "Debe tener 100 caracteres o menos")
                  .email("Dirección de correo electrónico inválida")
                  .required("Requerido"),
                phone: Yup.string()
                  .matches(phoneRegExp, "El número de teléfono no es válido")
                  .required("Requerido"),
                streetAddress: Yup.string()
                  .min(4, "Debe tener 4 caracteres o más")
                  .max(60, "Debe tener 60 caracteres o menos")
                  .required("Requerido"),
                city: Yup.string()
                  .min(4, "Debe tener 4 caracteres o más")
                  .max(40, "Debe tener 40 caracteres o menos")
                  .required("Requerido"),
                state: Yup.string()
                  .min(4, "Debe tener 4 caracteres o más")
                  .max(40, "Debe tener 40 caracteres o menos")
                  .required("Requerido"),
                country: Yup.string().required("Requerido"),
                postalCode: Yup.string()
                  .min(4, "Debe tener 4 caracteres o más")
                  .max(20, "Debe tener 20 caracteres o menos")
                  .required("Requerido"),
                packagePrice: Yup.string()
                  .min(4, "Debe tener 1 caracteres o más")
                  .max(20, "Debe tener 20 caracteres o menos")
                  .required("Requerido"),
                packageName: Yup.string()
                  .min(4, "Debe tener 4 caracteres o más")
                  .max(100, "Debe tener 100 caracteres o menos")
                  .required("Requerido"),
                adultsNumber: Yup.string().required("Requerido"),
                kidsNumber: Yup.string().required("Requerido"),
                language: Yup.string().required("Requerido"),
                invoiceDescription: Yup.string()
                  .max(255, "Debe tener255 caracteres o menos")
                  .required("Requerido"),
                vatValue: Yup.string()
                  .max(10, "Debe tener 10 caracteres o menos")
                  .required("Requerido"),
                status: Yup.string()
                  .max(50, "Debe tener 50 caracteres o menos")
                  .required("Requerido"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                setIsFailSubmission(false);

                invoiceCreate({
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  phoneNumber: values.phone,
                  address: values.streetAddress,
                  city: values.city,
                  state: values.state,
                  zipCode: values.postalCode,
                  country: values.country,
                  packageName: values.packageName,
                  packagePrice: values.packagePrice,
                  vatValue: values.vatValue,
                  adultsNumber: values.adultsNumber,
                  kidsNumber: values.kidsNumber,
                  language: values.language,
                  status: values.status,
                  invoiceDescription: values.invoiceDescription,
                }).then((data) => {
                  try {
                    if (data["result"]) {
                      if (data["result"] === "SUCCESS") {
                        successSubmission(data["data"]);
                      } else if (data["result"] === "FAILED") {
                        failSubmission("¡Error!", data["data"]["errorMessage"]);
                      } else {
                        failSubmission(
                          "¡Error!",
                          "¡Por favor, inténtelo de nuevo más tarde!"
                        );
                      }
                    } else {
                      failSubmission(
                        "¡Error!",
                        "¡Por favor, inténtelo de nuevo más tarde!"
                      );
                    }
                  } catch (error) {
                    if (data) {
                      if (data["result"]) {
                        if (data["result"] === "FAILED") {
                          //console.log(data["data"]);
                          failSubmission(
                            "¡Error!",
                            data["data"]["errorMessage"]
                          );
                        } else {
                          failSubmission(
                            "¡Error!",
                            "¡Por favor, inténtelo de nuevo más tarde!"
                          );
                        }
                      } else {
                        failSubmission(
                          "¡Error!",
                          "¡Por favor, inténtelo de nuevo más tarde!"
                        );
                      }
                    } else {
                      failSubmission(
                        "¡Error!",
                        "¡Por favor, inténtelo de nuevo más tarde!"
                      );
                    }
                  }
                });
              }}
            >
              <Form>
                <div className="row">
                  <div className="col-sm-4 mb-4">
                    <div className="row">
                      <div className="col-sm-12 mb-4 mt-5">
                        <h5 className="head-text-payment">
                          Ingrese los detalles del paquete
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="packageName"
                            type="text"
                            className="form-control"
                            placeholder="Nombre del Paquete"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="packageName">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="packagePrice"
                            type="text"
                            className="form-control"
                            placeholder="Precio del paquete($)"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="packagePrice">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="adultsNumber"
                            as="select"
                            className="form-control"
                            placeholder="Número de Adultos"
                            disabled={isLoading}
                          >
                            <option value="1">1 Adulto</option>
                            <option value="2">2 adultos</option>
                            <option value="3">3 adultos</option>
                            <option value="4">4 adultos</option>
                            <option value="5">5 adultos</option>
                            <option value="6">6 adultos</option>
                            <option value="7">7 adultos</option>
                            <option value="8">8 adultos</option>
                            <option value="9">9 adultos</option>
                            <option value="10">10 adultos</option>
                          </Field>
                          <ErrorMessage name="adultsNumber">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="kidsNumber"
                            as="select"
                            className="form-control"
                            placeholder="Número de Adultos"
                            disabled={isLoading}
                          >
                            <option value="0">Sin Niños</option>
                            <option value="1">1 Niño</option>
                            <option value="2">2 Niños</option>
                            <option value="3">3 Niños</option>
                            <option value="4">4 Niños</option>
                            <option value="5">5 Niños</option>
                            <option value="6">6 Niños</option>
                            <option value="7">7 Niños</option>
                            <option value="8">8 Niños</option>
                            <option value="9">9 Niños</option>
                            <option value="10">10 Niños</option>
                          </Field>
                          <ErrorMessage name="kidsNumber">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="status"
                            as="select"
                            className="form-control"
                            placeholder="Estado de la Factura"
                            disabled={isLoading}
                          >
                            <option value="Pending">Pago Pendiente</option>
                            <option value="Paid">Pago Hecho</option>
                          </Field>
                          <ErrorMessage name="status">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="language"
                            as="select"
                            className="form-control"
                            placeholder=""
                            disabled={isLoading}
                          >
                            <option value="es">Spanish</option>
                            <option value="en">English</option>
                          </Field>
                          <ErrorMessage name="language">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Label>
                            <b>Valor Fiscal</b>
                          </Label>
                          <Field
                            name="vatValue"
                            type="text"
                            className="form-control"
                            placeholder="Valor Fiscal"
                            disabled={true}
                          />
                          <ErrorMessage name="vatValue">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Label>
                            <b>Descripción de la Factura</b>
                          </Label>
                          <Field
                            name="invoiceDescription"
                            component="textarea"
                            rows={5}
                            className="form-control"
                            placeholder="Descripción de la Factura"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="invoiceDescription">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-8 mb-4 mt-5">
                    <div className="row">
                      <div className="col-sm-12 mb-4">
                        <h5 className="head-text-payment">
                          Ingrese los detalles del cliente
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="firstName"
                            type="text"
                            className="form-control"
                            placeholder="Primer nombre"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="firstName">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="lastName"
                            type="text"
                            className="form-control"
                            placeholder="Apellido"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="lastName">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="Correo electrónico"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="email">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="phone"
                            type="phone"
                            className="form-control"
                            placeholder="Número de teléfono"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="phone">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="streetAddress"
                            type="text"
                            className="form-control"
                            placeholder="Dirección"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="streetAddress">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="city"
                            type="text"
                            className="form-control"
                            placeholder="Ciudad"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="city">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="state"
                            type="text"
                            className="form-control"
                            placeholder="Estado"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="state">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="postalCode"
                            type="text"
                            className="form-control"
                            placeholder="Código Postal"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="postalCode">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                      <div className="col-sm-6 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="country"
                            as="select"
                            className="form-control"
                            placeholder="País"
                            disabled={isLoading}
                          >
                            <option selected value="">
                              Seleccionar país
                            </option>
                            <option value="ar">Argentina</option>
                            <option value="bo">Bolivia</option>
                            <option value="br">Brazil</option>
                            <option value="cl">Chile</option>
                            <option value="co">Colombia</option>
                            <option value="cr">Costa Rica</option>
                            <option value="ec">Ecuador</option>
                            <option value="sv">El Salvador</option>
                            <option value="gt">Guatemala</option>
                            <option value="us">Estado Unido</option>
                            <option value="mx">México</option>
                            <option value="pa">Panama</option>
                            <option value="py">Paraguay</option>
                            <option value="pe">Perú</option>
                            <option value="uy">Uruguay</option>
                          </Field>
                          <ErrorMessage name="country">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>

                    {isFailSubmission && (
                      <div className="container">
                        <div className="row">
                          <div className="col text-center">
                            <>
                              <Alert color="danger">
                                {/* <h4 className="alert-heading">Error!</h4> */}
                                <p>{errorMsg.head}</p>
                                <hr />
                                <p className="mb-0">{errorMsg.sub}</p>
                              </Alert>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col text-center">
                          <button
                            type="submit"
                            className="submit-payment btn"
                            disabled={isLoading}
                          >
                            Generate Invoice
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="row">
                        <div className="col text-center mt-3">
                          <Link className="back-btn" to="/">
                            Back to Home
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceForm;
